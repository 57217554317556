<template>
  <b-overlay
  :show="loading"
  rounded="lg">
  <div>
      <b-breadcrumb>
        <b-breadcrumb-item :to="`/`">
          <b-icon
            icon="house-fill"
            scale="1.25"
            shift-v="1.25"
            aria-hidden="true"
          ></b-icon>
        </b-breadcrumb-item>
        <b-breadcrumb-item active>Ongoing Drum Picking</b-breadcrumb-item>
      </b-breadcrumb>
      <br />
      <b-card title="Ongoing Drum Picking List">
        <b-modal
          id="promptView"
          ref="modal"
          v-model="processModal"
          title="Mama Drum Picking"
          hide-footer
          no-close-on-backdrop
          no-close-on-esc
          hide-close-button
          hide-header-close
        >
          <b-col cols="12">
            <b-row>
              <div>
                <p>
                  Can Pick Mama Drum?
                </p>
                <p style="font-size: 18px; font-weight: bold;">
                  Drum Number: {{ dataFetchRow.drumID }}
                </p>
                <p style="font-size: 18px; font-weight: bold;">
                  Bin Location: {{ dataFetchRow.binLocation }}
                </p>
                <p style="font-size: 18px; font-weight: bold;">
                  Quantity: {{ dataFetchRow.quantity }}
                </p>
                <p style="font-size: 18px; font-weight: bold;">
                  Line Item Description: {{ dataFetchRow.lineItemDescription }}
                </p>
              </div>
              <b-col cols="12" style="margin-bottom: 15px">
                <b-button
                  @click="reject()"
                  variant="danger"
                  class="float-right"
                  style="margin-left: 5px"
                  >No</b-button
                >
                <b-button
                  @click="changeStatus('Approved')"
                  variant="success"
                  class="float-right"
                  style="margin-right: 7px"
                  :disabled="disableCanPick"
                  >Yes</b-button
                >
              </b-col>
            </b-row>
          </b-col>
        </b-modal>
        <b-modal
          id="promptView"
          ref="modal"
          v-model="selectJobModal"
          title="Select Job Type"
          hide-footer
          no-close-on-backdrop
          no-close-on-esc
          hide-close-button
          hide-header-close
        >
          <b-col cols="12">
            <b-row>
              <div>
                <p>
                  Please Select Job Type First
                </p>
                <p style="font-size: 18px; font-weight: bold;">
                  Drum Number: {{ dataFetchRow.drumID }}
                </p>
                <p style="font-size: 18px; font-weight: bold;">
                  Bin Location: {{ dataFetchRow.binLocation }}
                </p>
                <p style="font-size: 18px; font-weight: bold;">
                  Quantity: {{ dataFetchRow.quantity }}
                </p>
                <p style="font-size: 18px; font-weight: bold;">
                  Line Item Description: {{ dataFetchRow.lineItemDescription }}
                </p>
              </div>
              <b-col cols="12" style="margin-bottom: 15px">
                <b-form-group label="Job type">
                  <b-form-select
                    id="selectJobType"
                    v-model="selectedJobType"
                    :options="jobList"
                  >
                  </b-form-select>
                  <b-button
                    @click="confirmJobType()"
                    variant="success"
                    class="float-right mt-2"
                    style="margin-right: 7px"
                    :disabled="disableConfirmJobType"
                    >Confirm Job Type</b-button
                >
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-modal>
        <b-modal
          id="promptView"
          ref="modal"
          v-model="waitingModal"
          title="Machine Option"
          hide-footer
          no-close-on-backdrop
          no-close-on-esc
          hide-close-button
          hide-header-close
        >
          <b-col cols="12">
            <b-row>
              <p>
                Please Select Machine Option
              </p>
              <b-col cols="12" style="margin-bottom: 15px">
                <b-button
                  @click="notWaitingMachine()"
                  variant="primary"
                  class="float-right"
                  >Machine can be Selected</b-button
                >
                <b-button
                  @click="waitingMachine()"
                  variant="success"
                  class="float-right"
                  style="margin-right: 7px"
                  :disabled="disableWait"
                  >Waiting for Machine</b-button
                >
              </b-col>
            </b-row>
          </b-col>
        </b-modal>
        <b-modal
            id="promptView"
            ref="modal"
            v-model="selectMachineModal"
            title="Select Machine"
            no-close-on-backdrop
            no-close-on-esc
            hide-close-button
            hide-header-close
          >
            <b-form-group label="Machine List" label-for="form-machine">
              <v-select
                v-model="machine"
                placeholder="----- Select Machine -----"
                :options="selectMachine"
                label="label"
                :clearable="false"
              />
            </b-form-group>
            <template #modal-footer>
              <b-button size="md" variant="success" @click="machineSelected()" :disabled="disableSelectMachine">
                Select
              </b-button>
              <b-button size="md" variant="primary" @click="refreshMachineList">
                Refresh
              </b-button>
            </template>
          </b-modal>
          <b-modal
            id="promptView"
            ref="modal"
            v-model="machineBusyModal"
            title="Machine is in use"
            no-close-on-backdrop
            no-close-on-esc
            hide-close-button
            hide-header-close
          >
            <p>
              <strong>Please wait for the Machine to finish the process first, or select another Machine.</strong>
            </p>
            <template #modal-footer>
              <b-button size="md" variant="success" @click="returnModal()">
                Return
              </b-button>
            </template>
          </b-modal>
        <b-modal
          id="promptView"
          ref="modal"
          v-model="babyModal"
          title="Baby Drum Pick"
          size="lg"
          hide-footer
          no-close-on-backdrop
          no-close-on-esc
          hide-close-button
          hide-header-close
        >
          <b-col cols="12">
            <b-row>
              <template>
                <div>
                  <p>
                    <strong>Machine Name :</strong> {{ station.name }}
                  </p>
                  <p>
                    <strong>SO Number :</strong> {{ dataFetchRow.orderNumber }}
                  </p>
                  <p>
                    <strong>Pick List Number :</strong>
                    {{ dataProps.pickListNumber }}
                  </p>
                  <p>
                    <strong>Baby Drum Type :</strong> {{ dataProps.babyDrumType }}
                  </p>
                  <p>
                    <strong>Baby Drum Size :</strong> {{ dataProps.babyDrumSize }}
                  </p>
                  <p>
                    <strong>Timer:</strong> {{ (this.startTimer==null)?"":totalTimer }}
                  </p>
                </div>
              </template>
              <b-col cols="12" style="margin-bottom: 15px">
                <b-button
                  @click="finishPick()"
                  variant="danger"
                  class="float-right"
                  style="margin-left: 2px"
                  :disabled="disableFinish"
                  >End Baby Drum Picking</b-button
                >
                <b-button
                  @click="startPick()"
                  variant="success"
                  class="float-right"
                  style="margin-right: 7px"
                  :disabled="disableStart"
                  >Start Baby Drum Picking</b-button
                >
              </b-col>
            </b-row>
          </b-col>
        </b-modal>
        <b-modal
          id="promptView"
          ref="modal"
          v-model="drumProcessModal"
          title="Mama Drum Picking"
          hide-footer
          no-close-on-backdrop
          no-close-on-esc
          hide-close-button
          hide-header-close
        >
          <b-col cols="12">
            <b-row>
              <template>
                <div>
                  <p>
                    <strong>Job Type :</strong>
                    {{ this.selectedJobType ? this.selectedJobType : dataFetchRow.props ? dataFetchRow.props.type : '' }}
                  </p>
                  <p>
                    <strong>Pick List :</strong>
                    {{ dataFetchRow.pickList }}
                  </p>
                  <p style="font-size: 18px; font-weight: bold;">
                    Drum Number: {{ dataFetchRow.drumID }}
                  </p>
                  <p style="font-size: 18px; font-weight: bold;">
                    Bin Location: {{ dataFetchRow.binLocation }}
                  </p>
                  <p style="font-size: 18px; font-weight: bold;">
                    Quantity: {{ dataFetchRow.quantity }}
                  </p>
                  <p style="font-size: 18px; font-weight: bold;">
                    Line Item Description: {{ dataFetchRow.lineItemDescription }}
                  </p>
                  <p>
                    Timer: {{ (this.startTimer==null)?"":totalTimer }}
                  </p>
                </div>
              </template>
              <b-col cols="12" style="margin-bottom: 15px">
                <b-button
                  @click="finishPickDrum()"
                  variant="danger"
                  class="float-right"
                  style="margin-left: 5px"
                  :disabled="disableFinish"
                  >End</b-button
                >
                <b-button
                  @click="startPickDrum(dataFetchRow.props.type)"
                  variant="success"
                  class="float-right"
                  style="margin-right: 2px"
                  :disabled="disableStart"
                  >Start</b-button
                >
              </b-col>
            </b-row>
          </b-col>
        </b-modal>
        <b-modal
          id="promptView"
          ref="modal"
          v-model="rejectModal"
          title="Input Reason"
        >
          <v-select
            v-model="reason"
            placeholder="Select Reason"
            :options="reasonListDrumPick"
            :rules="reasonRules"
            required
          ></v-select>
          <template #modal-footer>
            <div>
              <b-button @click="changeStatus('Rejected')" variant="primary" :disabled="disableCantPick"
                >Submit</b-button
              >
            </div>
          </template>
        </b-modal>
        <!-- ===================== -->
        <b-col cols="12">
          <b-row class="mb-2">
            <b-col cols="4" />
            <b-col cols="4">
              <v-select
                class="style-chooser"
                v-model="searchSoNumber"
                :options="soNumberList"
                @search="ajaxSearch"
                placeholder="Filter by SO Number"
                :clearSearchOnSelect="true"
              />
            </b-col>
            <b-col cols="4">
              <b-form-input
                v-model="search"
                @input="debounceSearch"
                type="search"
                placeholder="Type to Search"
                style="float: right;"
              />
            </b-col>
          </b-row>
          
          <b-row>
            <b-col cols="12">
              <b-table
                :filter="search"
                :tbody-tr-class="rowClass"
                hover
                responsive
                small
                head-variant="dark"
                outlined
                :items="filteredItems"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                show-empty
              >
                <template v-slot:cell(actions)="row">
                    <b-dropdown variant="success" size="sm" text="Menu" no-caret right>
                      <template #button-content>
                        <feather-icon size="1x" icon="MenuIcon"></feather-icon>
                      </template>
                      <b-dropdown-item>
                        <b-button
                          variant="success"
                          class="mr-1"
                          size="sm"
                          :to="{
                            name: 'view-fetch',
                            params: { id: row.item.id },
                          }"
                        >
                          <feather-icon size="1x" icon="EyeIcon"></feather-icon>
                        </b-button>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          row.item.status == 'Picking' &&  row.item.babyormama == 'Mama Drum' || row.item.status == 'Picking Started'
                        "
                      >
                        <b-button
                          variant="success"
                          class="mr-1"
                          @click="drumProcess(row.item)"
                          size="sm"
                        >
                          Pick Mama Drum
                        </b-button>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          row.item.status == 'Picking Ended' &&  row.item.babyormama == 'Mama Drum'
                        "
                      >
                        <b-button
                          variant="success"
                          @click="waitProcess(row.item)"
                          size="sm"
                        >
                          Machine Options
                        </b-button>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          row.item.status === 'Created' &&
                          row.item.props.needPicker != true
                        "
                      >
                        <b-button
                          variant="warning"
                          class="mr-1"
                          @click="fetchProcess(row.item)"
                          size="sm"
                        >
                          Process
                        </b-button>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          row.item.props.needPicker === true &&
                            row.item.status != 'Machine Selected'
                        "
                      >
                        <b-button
                          variant="warning"
                          class="mr-1"
                          @click="babyDrumPick(row.item)"
                          size="sm"
                        >
                          Process Baby Drum
                        </b-button>
                      </b-dropdown-item>
                    </b-dropdown>
                </template>
                <template v-slot:cell(binLocation)="data">
                  <div class="custom-bold-and-large">{{ data.value }}</div>
                </template>
                <template v-slot:cell(fromBatch)="data">
                  <div class="custom-bold-and-large">{{ data.value }}</div>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4" class="pt-1">
              <b-form-group
                label="Data Per Page"
                label-for="per-page-select"
                label-cols-md="0"
                label-align-sm="left"
                label-size="md"
                class="mb-0"
              >
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
                  @input="queryDrumPicking()"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col class="pt-1">
              <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                @change="onPageChange"
                :total-rows="totalRows"
                first-number
                last-number
                class="float-right"
                aria-controls="user-table"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-col>
      </b-card>
      <!-- modal for idle time out -->
      <b-modal
        v-model="afkmodal"
        id="modal-prevent-closing"
        ref="modal"
        no-close-on-backdrop
        no-close-on-esc
      >
        <template #modal-header="{}">
          <!-- Emulate built in modal header close button action -->
          <h5>Pause Job</h5>
        </template>
  
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            label="Select a Reason:"
            label-for="reason-select"
            invalid-feedback="Please select a reason"
            :state="reasonState"
          >
            <b-form-select
              id="reason-select"
              v-model="pauseReasonDefault"
              :options="pauseReasonOptions"
              :state="reasonState"
              required
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Please select a your Pause Reason --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </form>
        <template #modal-footer="">
          <b-button :disabled="loading" @click="restartAfkModal">save</b-button>
        </template>
      </b-modal>
    </div>
  </b-overlay>
  </template>
  <script>

  import { mapActions, mapGetters } from "vuex";
  import { dateFormat, userAccess,sleep } from "@/utils/utils.js";
  
  export default {
    data() {
      return {
        reasonRules: [(value) => !!value || "Please select a reason"],
        tempEditId: "",
        tempEditData: "",
        disableStart: false,
        disableFinish: true,
        disableWait: false,
        disableCanPick: false,
        disableConfirmJobType: false,
        disableCantPick: false,
        disableSelectMachine: false,
        stackedStatus: "md",
        dataProps: {},
        editDetailJOState: false,
        detailJOState: false, // Job Order detail when job order has done to select
        search: "",
        reason: "",
        filterStatus: "All",
        statusOptions: ["All", "Job To Do", "Completed"],
        // detailStatus: false,
        addModal: false, // modal for add fetch
        detailModal: false,
        processModal: false,
        drumProcessModal: false,
        selectJobModal: false,
        babyModal: false,
        waitingModal: false,
        rejectModal: false,
        selectMachineModal: false,
        machineBusyModal: false,
        editModal: false,
        modalDelete: false,
        showSpinner: false,
        hasSelectMamaDrum: true,
        dataFetchRow: {},
        station: {},
        dataBabyRow: "",

        searchSoNumber: "",
        soNumberList: [],
  
        // selectedPicklist: null,

        selectedJobType: "",
  
        mamaDrumSelected: "",
  
        totalRow: 1,
        pageOptions: [5, 10, 20, 100],
        sortBy: "",
        sortDesc: false,
        buttonState: false,
        currentPage: 1,
        perPage: 5,
        totalRows: 1,

  
        tempId: "",
        tempOrderNumber: "",
  
        detailJO: {
          status: "",
          props: { type: "" },
        },
        detailEditJO: {
          status: "",
          props: { type: "" },
        },
        fetchDeleteSelected: "",
        fetchDetailSelected: "",
        fetchDetailItem: [],
        // data for detail of job order selected
        tableDetailJO: "",
        tableDetailEditJO: "",
        fetchList: "",
        form: {
          status: "Machine Selected",
          machine: {},
        },
        formEdit: "",
        fieldsDetailFetch: [
          { key: "orderNumber", label: "Item" },
          { key: "actions", label: "Actions" },
        ],
  
        fieldsDetailJO: [
          { key: "orderNumber", label: "SO Number" },
          { key: "item.name", label: "Name" },
          { key: "quantity", label: "Qty" },
        ],
  
        fields: [
          // field of table
          // { key: "props.jobOrderNumber", label: "JO Number" },
          { key: "props.number", label: "Job No" },
          { key: "uniquePicklistsCount", label: "No of Picklist" },
          { key: "lineItemDescription", label: "Line Item Description"},
          { key: "quantity", label: "Quantity"},
          { key: "binLocation", label: "Bin Location"},
          { key: "fromBatch", label: "Drum"},
          { key: "type", label: "Type" },
          { key: "status", label: "Status" },
          { key: "babyormama", label: "Mama/Baby" },
          { key: "createdAtFormatted", label: "Created Date" },
          { key: "cableWeight", label: "Cable Weight"},
          { key: "actions", label: "Actions" },
        ],
  
        jobList: [ "Cutting", "Direct Sale"],
  
        //Pause
        afkmodal: false,
        pauseReasonDefault: null,
        pauseReasonOptions: [],
        reasonState: null,
        pauseIntervalID: null,
        logoutIntervalID: null,
        IDLE_TIMEOUT: 0,
        idleSecondsCounter: 0,

        //Interval ID
        idleSecondsTimer: null,
        modalToLogout: null,

        //notify
        notifyModal:false,
        notifyData:{},
        startTimer:null,
        totalTimer:"",

        //for loading
        loading : false,

        machineDone : false,

        reasonListDrumPick: [],

        machine: null,
      };
    },
    mounted() {

      this.$store.dispatch("plant/getStations");

      this.$store.dispatch("plant/getReasonList").then((res) => {
          var drumPickReason = this.$store.getters["plant/getReasonList"].filter(
            (x) => x.reasonName == "Drum Picker"
          );
          var allreason = drumPickReason[0].reasonList;
          var activeReason = allreason.filter((x) => x.status === "Active")
          this.reasonListDrumPick = activeReason.map((x) => x.reason)
        });
        
        // Saving Menu Setting on localstorage session so it still same even after reloading the page
        if (this.$session.has("perPageOngoingDrumPicking")) {
          this.perPage = this.$session.get("perPageOngoingDrumPicking");
        }
        if (this.$session.has("sortByOngoingDrumPicking")) {
          this.sortBy = this.$session.get("sortByOngoingDrumPicking");
        }
        if (this.$session.has("sortDescOngoingDrumPicking")) {
          this.sortDesc = this.$session.get("sortDescOngoingDrumPicking");
        }

      this.queryDrumPicking();

      console.log(this.perPage,this.currentPage);

      
      // console.log("filtered items", this.filteredItems)
      // this.$store.dispatch("fetch/getOngoingFetch");

  
      //idle time out modal mounted
      let user = this.$store.getters["auth/getActiveUser"]
      this.$store.dispatch("pause/getActivePauseJob", user).then((res)=> {
      /* 
      result "res.pause" will be true or false
      if database has document pausejob with current username and idleRun = true,
      modal will appeared
      */
      this.afkmodal = res.pause
      res.pause ? this.startLogoutInterval() : this.startPauseInterval()
    })
      this.$store.dispatch("pause/getPauseJobSetting").then((res) =>{
      this.IDLE_TIMEOUT = res[0].intervalTime * 60
    })
      document.onclick = () => {
        this.idleSecondsCounter = 0;
      };
      document.onmousemove = () => {
        this.idleSecondsCounter = 0;
      };
      document.onkeydown = () => {
        this.idleSecondsCounter = 0;
      };
      document.onscroll = () => {
        this.idleSecondsCounter = 0;
      };
      // this.idleSecondsTimer = window.setInterval(this.CheckIdleTime, 1000);
    },
    destroyed() {
    this.stopPauseInterval();
    this.stopLogoutInterval();
  },
    methods: {
      ...mapActions({
        getDrumPickingOrders: "fetch/getOngoingFetch",
      }),

      processMetadata(metadata) {
        let { totalData } = metadata;
        this.totalRows = totalData;
      },

      queryDrumPicking(){
        this.getDrumPickingOrders({
          entry : this.perPage,
          page : this.currentPage,
        }).then((data) => {
          // console.log("data", data)
          this.processMetadata(data.metadata)
        }).catch((e) => {
          this.$bvToast.toast("Load data failed, please reload the page.", {
          title: "Warning",
          variant: "danger",
          solid: true,
          });
          console.log(e);
        });
      },
    
      onPageChange(page) {
        this.currentPage = page;
        this.queryDrumPicking();
      },
      //Pause Functions - START
    restartAfkModal() {
      this.loading = true
      if (!this.checkFormValidity()) {
        this.loading = false
        return;
      }
      document.onclick = () => {
        this.idleSecondsCounter = 0;
      };
      document.onmousemove = () => {
        this.idleSecondsCounter = 0;
      };
      document.onkeydown = () => {
        this.idleSecondsCounter = 0;
      };
      document.onscroll = () => {
        this.idleSecondsCounter = 0;
      };
      this.startPauseInterval()

      if (this.pauseReasonDefault != null) {
        var pauseData = {
          user: this.userInfo,
          pauseReason: this.pauseReasonDefault,
          pauseReference: "Ongoing Drum Picking"
        };
        this.$store
          .dispatch("pause/savePauseJob", pauseData )
          .then((res) => {
            this.loading = false
            this.afkmodal = false;
            this.stopLogoutInterval();
            this.$bvToast.toast("PauseJob Updated", {
              title: "Success",
              variant: "success",
              solid: true,
            });
          })
          .catch((err) => {
            this.loading = false
            this.afkmodal = false;
            this.$bvToast.toast("Cannot save your pause Job", {
              title: "Error",
              variant: "warning",
              solid: true,
            });
          });
      }
    },
    checkIdleTime() {
      this.idleSecondsCounter++;
      // console.log(this.IDLE_TIMEOUT,":",this.idleSecondsCounter);
      if (this.idleSecondsCounter == this.IDLE_TIMEOUT) {
        var pauseData = {
          user: this.userInfo,
          pauseTimestamp: new Date(),
          props: {
            idleRun: true,
          },
          pauseInterval: this.pauseSetting.intervalTime,
        };
          this.$store
            .dispatch("pause/addPauseJob", pauseData)
            .then((res) => {
              this.stopPauseInterval();
              this.startLogoutInterval()
              this.afkmodal = true;
              this.$bvToast.toast("PauseJob Added", {
                title: "Success",
                variant: "success",
                solid: true,
              });
            })
            .catch((err) => {
              this.$bvToast.toast(err, {
                title: "Error",
                variant: "warning",
                solid: true,
              });
            });
      }
    },
    /* 
      when idle timeout modal showed, this function will started
      and running an interval to check if current time as same as
      auto logout time from pause setting, if it happend system will auto logout 
    */
    idleToLogout() {
      var TimeSet = this.pauseSetting.logoutSessionMorning.split(":");
      var TimeSet2 = this.pauseSetting.logoutSessionNight.split(":");
      console.log(new Date().getHours(), ':',TimeSet[0], ' ', new Date().getMinutes(),':' ,TimeSet[1]);
      if (
        (new Date().getHours() == TimeSet[0] &&
          new Date().getMinutes() == TimeSet[1]) ||
        (new Date().getHours() == TimeSet2[0] &&
          new Date().getMinutes() == TimeSet2[1])
      ) {
        this.stopLogoutInterval();
        var pauseData = {
          user: this.userInfo,
          pauseReason: "Idle auto Logout",
          joNumber: this.form.jobOrder.orderNumber,
          picklisNO: this.form.pickListNumber,
          pauseReference: "HMI Cutter"
        };
        this.$store
        .dispatch("pause/savePauseJob", pauseData )
        .then((res) => {
          this.$bvToast.toast("PauseJob Updated", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          //logout function
          axios.post("/common/auth/logout").then(() => {        
            this.$session.clear()
            this.$session.destroy()
            window.location.reload()
          })
          .catch((err) => {
            this.$bvToast.toast(err.response.data.errors[0].message, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          });
        })
        .catch((err) => {
          this.$bvToast.toast(err, {
            title: "Error",
            variant: "warning",
            solid: true,
          });
        });
      }
    },
    startPauseInterval() {
      this.stopPauseInterval();
      this.pauseIntervalID = window.setInterval(this.checkIdleTime, 1000)
      console.log("startPauseInterval init");
    },
    stopPauseInterval() {
      window.clearInterval(this.pauseIntervalID);
      console.log("stopPauseInterval init");
    },
    startLogoutInterval() {
      this.logoutIntervalID = window.setInterval(this.idleToLogout, 1000);
      console.log("startLogoutInterval init");
    },
    stopLogoutInterval() {
      window.clearInterval(this.logoutIntervalID);
      console.log("stopLogoutInterval init");
    },
      async timerRunStart(){
        var currentTime = new Date().getTime();
        var startTime = new Date(this.startTimer).getTime();
        var elapsedTime = currentTime - startTime;

        const hours = Math.floor(elapsedTime / (1000 * 60 * 60));
      const minutes = Math.floor(
        (elapsedTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((elapsedTime % (1000 * 60)) / 1000);

      this.totalTimer= `${hours
        .toString()
        .padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

        await sleep(1000)
        if(this.startTimer!=null){
          this.timerRunStart()
        }
      },
      checkFormValidity() {
        const valid = this.$refs.form.checkValidity();
        this.reasonState = valid;
        return valid;
      },
      debounceSearch(event) {
        this.showSpinner = true;
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          this.showSpinner = false;
          this.search = event;
        }, 600);
      },
      
      rowClass(item, type) {
        if (!item || type !== "row") return;
        if (item.props.rejectedMachine == true) return "row-reject";
        if (item.props.rejectedDirectSale == true) return "row-reject";
        if (item.props.waitingForMachine == true) return "row-waiting";
        if (item.props.urgentJob == true) return "row-urgent";
      },
      async changeStatus(type) {
        var picklistArr = [];
        let itemArr = this.dataFetchRow.item.map((el) => {
          return {
            quantity: el.quantity,
            orderNumber: el.orderNumber,
            pickList: el.pickList.id,
            status: el.status,
            _id: el._id,
          };
        });
        itemArr.map((el) => {
          console.log("el", el)
          picklistArr.push(el.pickList);
        });

        let numberStatus = "";
        if (type === "Approved") {
          this.disableCanPick = true;
          numberStatus = 15;
        } else if (type === "Rejected") {
          this.disableCantPick = true;
          this.startPauseInterval() // to start again pause interval
          if(this.reason === undefined || this.reason === ''){
            return this.$bvToast.toast("Please input reason", {
                  title: "Error",
                  variant: "danger",
                  solid: true,
                });
          }
          numberStatus = 60;
        }
  
        let sendStatus = {
          props: {
            ...this.dataFetchRow.props,
            app: this.$session.get("phibase-app"),
            status: numberStatus,
            reason: this.reason,
          },
          updatedAt: new Date(),
        };
        sendStatus.status = type;

        let payload = {
          type : type,
          sendStatus : sendStatus,
          picklistArr : picklistArr,
          reason : this.reason
        }

        if (type == "Approved"){
          this.disableCanPick = false;
          this.drumProcessModal = true;
        }

        console.log("payload", payload)
  
        await this.$store
          .dispatch("fetch/changeStatus", {
            id: this.dataFetchRow.id,
            data: payload,
          })
          .then(() => {
            this.processModal = false;
            this.rejectModal = false;
            this.$bvToast.toast(`Successfully change status to ${type}`, {
              title: "Success",
              variant: "success",
              solid: true,
            });
            this.disableCantPick = false;
            this.queryDrumPicking()
          })
          .catch((err) => {
            this.$bvToast.toast("Drum Picking ALready Processed, Please Refresh the Page", {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
            this.processModal = false;
            this.rejectModal = false;
            this.drumProcessModal = false;
            this.disableCantPick = false;
            return
          });
      },
      reject() {
        this.rejectModal = true;
      },
      fetchProcess(item) {
        this.processModal = true;
        this.dataFetchRow = item;
        // console.log(this.dataFetchRow)

        this.$store
          .dispatch("joborder/jobOrderTimestamp", {
            id: item.JobOrderID,
            timestamp: "MDS",
          })
          .then(() => {
            this.startTimer = new Date();
            this.timerRunStart();
            this.stopPauseInterval()
            this.$bvToast.toast("Selecting Mama Drum", {
              title: "Success",
              variant: "success",
              solid: true,
            });
          })
          .catch((err) => {
            this.$bvToast.toast(err, {
              title: "Error",
              variant: "warning",
              solid: true,
            });
          });
      },

      // process mama drum method //

      async drumProcess(item) {
        this.stopPauseInterval()
        var jobOrder;
        try{
          jobOrder = await this.$store.dispatch("joborder/getJobOrderId", {
          id: item.JobOrderID,
        });
        }catch(err){
          return;
        }
        if(jobOrder.props.MDS!==undefined && jobOrder.props.status == 15){
          this.startTimer= new Date(jobOrder.props.MDS);
          this.disableStart=false;
          this.disableFinish=true;
          this.timerRunStart();
        }

        if(jobOrder.props.status == 20){
          console.log("status 20");
          this.startTimer= new Date(jobOrder.props.MDS);
          this.timerRunStart();
          this.disableStart=true;
          this.disableFinish=false;
        }

        if (item.status == "Picking Started" && item.props.jobTypeConfirmed == undefined || item.status == "Picking Started" && item.props.jobTypeConfirmed == false) {
          this.drumProcessModal = false;
          this.dataFetchRow = item;
          this.selectJobModal = true;
          this.disableConfirmJobType = false;
          this.selectedJobType = item.props.type;
          this.disableStart = true;
          this.disableFinish = false;
        }

        if (item.status == "Picking Started" && item.props.jobTypeConfirmed == true) {          
          this.drumProcessModal = true;
          this.dataFetchRow = item;
          this.selectedJobType= item.props.type
          this.dissableStart = true;
          this.dissableFinish = false;
        }

        if (item.status == "Picking Ended" && item.props.jobTypeConfirmed == true) {          
          this.waitingModal = true;
          this.dataFetchRow = item;
          this.selectedJobType= item.props.type
          this.dissableStart = true;
          this.dissableFinish = true;
        }

        if (item.status == "Picking"){
          this.drumProcessModal = true;
          this.dataFetchRow = item;
          this.dissableStart = false;
          this.dissableFinish = true;
        }
      },
      waitProcess(item) {
        this.dataFetchRow=item;
        this.selectedJobType= item.props.type
        this.waitingModal = true;
      },
      returnModal() {
        this.machineBusyModal = false;
        this.waitingModal = true;
      },

      // process baby drum method //

      async babyDrumPick(item) {

        this.dataBabyRow = item.props.pickListNumber;

        var pickList;
        try{
          pickList = await this.$store.dispatch("picklist/getPicklistByPicklistNo", {
          picklistNo: this.dataBabyRow,
        });
        }catch(err){
          return;
        }
        if(pickList.props.BDS!==undefined){
          this.startTimer= new Date(pickList.props.BDS);
          this.disableStart=false;
          this.disableFinish=false;
          this.timerRunStart();
          this.stopPauseInterval();
          //if idle timer is running, disable
          if(this.idleSecondsTimer!=null){
            //console.info('idle second timer off')
            window.clearInterval(this.idleSecondsTimer);
            this.idleSecondsTimer=null;
          }
        }      

        if (item.status == "Created") {
          this.disableFinish = true;
        } else if (item.status == "Picking") {
          this.disableStart = true;
          this.disableFinish = false;
        }
  
        this.dataProps = item.props;
  
        if (this.dataProps.babyDrumType == "W") {
          this.dataProps.babyDrumType = "Wooden"
        }
        else if (this.dataProps.babyDrumType == "S") {
          this.dataProps.babyDrumType = "Steel"
        }
  
        if (this.dataProps.babyDrumType == "A") {
          this.dataProps.babyDrumType = "Active"
        }
        if (this.dataProps.babyDrumType == "I") {
          this.dataProps.babyDrumType = "Inactive"
        }
  
        this.babyModal = true;
  
        this.$store.dispatch("plant/getStationId", { id: item.props.station });
        this.dataFetchRow = item;
        this.station = this.$store.getters["plant/getStations"].find(
          (el) => el.id === this.dataFetchRow.props.station)
  
      },

      // start pick mama drum //

      async startPickDrum(item) {
        this.disableStart = true;
        this.disableFinish = false;
  
        this.selectedJobType = item
        var picklistArr = [];
        let itemArr = this.dataFetchRow.item.map((el) => {
          return {
            quantity: el.quantity,
            orderNumber: el.orderNumber,
            pickList: el.pickList.id,
            status: el.status,
            _id: el._id,
          };
        });
        itemArr.map((el) => {
          picklistArr.push(el.pickList.id);
        });
        
        let sendStatus = {
          props: {
            ...this.dataFetchRow.props,
            type: this.selectedJobType,
            app: this.$session.get("phibase-app"),
            status: 15,
            isPicking: true,
          },
          updatedAt: new Date(),
        };

        // console.log("sendStatus", sendStatus)

        sendStatus.status = "Picking Started";
  
        await this.$store
          .dispatch("fetch/startPickDrum", {
            id: this.dataFetchRow.id,
            data: sendStatus,
          })
          .then(() => {
            this.$bvToast.toast("Successfully change status to 'Picking Started'", {
              title: "Success",
              variant: "success",
              solid: true,
            });
            if(item!="Recoil"){
              this.selectJobModal = true;
              this.disableConfirmJobType = false;
            }
            this.queryDrumPicking()
        })
        .catch((err) => {
            this.$bvToast.toast("Drum Picking ALready Processed, Please Refresh the Page", {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
            this.disableStart = false;
            this.disableFinish = true;
            this.drumProcessModal = false;
            this.selectJobModal = false;
            return
          });
      },
  
      async confirmJobType() {
        this.loading = true;
        this.disableConfirmJobType = true;
        var picklistArr = [];
        let itemArr = this.dataFetchRow.item.map((el) => {
          return {
            quantity: el.quantity,
            orderNumber: el.orderNumber,
            pickList: el.pickList.id,
            status: el.status,
            _id: el._id,
          };
        });
        itemArr.map((el) => {
          picklistArr.push(el.pickList.id);
        });
        
        let sendStatus = {
          props: {
            ...this.dataFetchRow.props,
            type: this.selectedJobType,
            app: this.$session.get("phibase-app"),
            status: 20,
            jobTypeConfirmed: true
          },
          updatedAt: new Date(),
        };
  
        if ( this.selectedJobType == "Direct Sale"){
  
            sendStatus.status = "Process in Direct Sales";
            sendStatus.props.status = 10
          
            await this.$store
              .dispatch("fetch/confirmJobType", {
                id: this.dataFetchRow.id,
                data: sendStatus,
              })
              .then(() => {
                this.queryDrumPicking()
                this.$bvToast.toast("Successfully change status to 'Process in Direct Sales'", {
                  title: "Success",
                  variant: "success",
                  solid: true,
                });
                // this.$store.dispatch("fetch/getOngoingFetch");
                this.loading = false;
                this.disableConfirmJobType = false;
                this.selectJobModal = false;
                this.drumProcessModal = true;
            })
            .catch((err) => {
              this.$bvToast.toast("Drum Picking ALready Processed, Please Refresh the Page", {
                title: "Failed",
                variant: "danger",
                solid: true,
              });
              this.loading = false;
              this.disableConfirmJobType = false;
              this.selectJobModal = false;
              this.drumProcessModal = false;
              return
            });
          }
  
        if ( this.selectedJobType == "Cutting"){
  
          await this.$store
              .dispatch("fetch/confirmJobType", {
                id: this.dataFetchRow.id,
                data: sendStatus,
              })
              .then(() => {
                this.queryDrumPicking()
                this.$bvToast.toast("Successfully confirm Job Type", {
                  title: "Success",
                  variant: "success",
                  solid: true,
                });
                // this.$store.dispatch("fetch/getOngoingFetch");
                this.loading = false;
                this.disableConfirmJobType = false;
                this.selectJobModal = false;
                this.drumProcessModal = true;
            })
            .catch((err) => {
              this.$bvToast.toast("Drum Picking ALready Processed, Please Refresh the Page", {
                title: "Failed",
                variant: "danger",
                solid: true,
              });
              this.loading = false;
              this.disableConfirmJobType = false;
              this.selectJobModal = false;
              this.drumProcessModal = false;
              return
            });
          }
      },

      // finish pick mama drum //
  
      async finishPickDrum() {
        this.disableStart = false;
        this.disableFinish = true;
        var picklistArr = [];
        this.startTimer=null
        let itemArr = this.dataFetchRow.item.map((el) => {
          return {
            quantity: el.quantity,
            orderNumber: el.orderNumber,
            pickList: el.pickList.id,
            status: el.status,
            _id: el._id,
          };
        });
        itemArr.map((el) => {
          picklistArr.push(el.pickList.id);
        });
  
        let sendStatus = {
          props: {
            ...this.dataFetchRow.props,
            type: this.selectedJobType,
            app: this.$session.get("phibase-app"),
            jobTypeConfirmed: true,
            status: 25,
          },
          timestamp: "MDE",
          updatedAt: new Date(),
        };

        let payload = {
          timestamp: "MDE",
          sendStatus: sendStatus
        }
  
        sendStatus.status = "Picking Ended";
  
        await this.$store
          .dispatch("fetch/finishPickDrum", {
            id: this.dataFetchRow.id,
            data: payload,
          })
          .then(() => {
            this.queryDrumPicking()
            // this.startPauseInterval()
            this.$bvToast.toast("Successfully change status to 'Picking Ended'", {
              title: "Success",
              variant: "success",
              solid: true,
            });
            if (this.selectedJobType == "Cutting" ||this.selectedJobType == "Recoil") {
              this.drumProcessModal = false;
              this.waitingModal = true;
            }
            if (this.selectedJobType == "Direct Sale") {
              this.drumProcessModal = false;
            }
          })
          .catch((err) => {
              this.$bvToast.toast("Drum Picking ALready Processed, Please Refresh the Page", {
                title: "Failed",
                variant: "danger",
                solid: true,
              });
              this.waitingModal = false;
              this.drumProcessModal = false;
              this.disableStart = true;
              this.disableFinish = false;
              return
            });
      },

      // loopGetMachine() {
      //   this.$store.dispatch("plant/getStations").then((res)=>{
      //     if (this.machineDone) {
      //       setTimeout(() => {
      //           this.loopGetMachine()
      //       }, 2000);
      //     }
      //   }).catch((err) =>{
      //     if (this.machineDone) {
      //       setTimeout(() => {
      //           this.loopGetMachine()
      //       }, 2000);
      //     }
      //   })
      // },

      refreshMachineList() {
        this.loading = true;

        this.$store.dispatch("plant/getStations")
        .then((res) => {
          this.$bvToast.toast("Machine List Refreshed", {
              title: "Success",
              variant: "success",
              solid: true,
            });
            this.disableSelectMachine = false;
            this.loading = false;
        })
        .catch((err) => {
            this.$bvToast.toast("Failed to Refresh Machine List", {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
            this.disableSelectMachine = false;
            this.loading = false;
            return
          });
      },
  
      notWaitingMachine() {
        this.waitingModal = false;
        this.disableSelectMachine = false;
        this.selectMachineModal = true;
        this.machineDone = true
        // this.loopGetMachine()
      },
  
      async waitingMachine() {
        this.loading = true;
        this.disableWait = true;
        var dataJO = this.dataFetchRow
        console.log("dataJo", dataJO)

        let itemArr = this.dataFetchRow.item.map((el) => {
          return {
            quantity: el.quantity,
            orderNumber: el.orderNumber,
            pickList: el.pickList.id,
            status: el.status,
            _id: el._id,
          };
        });
  
        let newFetchData = {
          orderNumber: dataJO.orderNumber,
          JobOrderID: dataJO.JobOrderID,
          drumID: dataJO.drumID,
          item: itemArr,
          props: dataJO.props,
        };
        newFetchData.props.jobTypeConfirmed = false
        newFetchData.props.type = this.selectedJobType
        newFetchData.props.waitingForMachine = true;
        newFetchData.props.isPicking = false;

        let payload = { 
          status: "Completed",
          newFetchData: newFetchData
        };
        // update current fetch to waiting
        await this.$store
          .dispatch("fetch/waitingMachine", {
            id: this.dataFetchRow.id,
            data: payload,
          })
            .then(() => {
              this.startPauseInterval()
              this.queryDrumPicking()
              this.selectMachineModal = false;
              this.waitingModal = false;
              this.$bvToast.toast("Successfully added new Fetch data", {
                title: "Success",
                variant: "success",
                solid: true,
              });
              this.loading = false;
              this.disableWait = false;
            })
            .catch((err) => {
              console.log(err);
              this.selectMachineModal = false;
              this.$bvToast.toast("Failed to add new Fetch data / Drum Picking job already processed, please refresh the page", {
                title: "Failed",
                variant: "danger",
                solid: true,
              });
              this.loading = false;
              this.disableWait = false;
              return
            });
      },
  
      machineSelected() {
        if (this.machine === null) {
          this.$bvToast.toast("Please select machine from the list", {
              title: "Can't Select Machine",
              variant: "warning",
              solid: true,
            });
          this.disableSelectMachine = false;
          return
        }
        this.loading = true;
        this.disableSelectMachine = true;
        var data = this.dataFetchRow
        var station = this.$store.getters["plant/getStations"].find(
          (el) => el.id === this.machine.id
        )
        var payload = {
          fetchID: data.id,
          machineID: station.id
        }
        // console.log(payload);
       this.$store
          .dispatch("fetch/selectMachine", payload)
          .then((res) => {
            this.startPauseInterval()
            this.machine = null
            this.queryDrumPicking()
            this.$store.dispatch("plant/getStations");
            this.$bvToast.toast(res.data, {
              title: "Success",
              variant: "success",
              solid: true
            });
            this.loading = false;
            this.disableSelectMachine = false;
            this.selectMachineModal = false;
            this.machineDone = false
          })
          .catch((err) => {
            this.$bvToast.toast("Drum Picking ALready Processed, Please Refresh the Page", {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
            this.loading = false;
            this.disableSelectMachine = false;
            return
          });
      },
      
      // method to start pick baby drum //

      startPick() {
        this.disableStart = true;
        this.disableFinish = false;
        var sendStatus = this.dataFetchRow.props;
  
        var update = { status: "Approved", props: sendStatus };
  
        this.$store
          .dispatch("fetch/updateFetch", {
            id: this.dataFetchRow.id,
            data: update,
          })
          .then(() => {
            // this.startTimer=new Date();
            // this.timerRunStart();
            // if(this.idleSecondsTimer!=null){
            // window.clearInterval(this.idleSecondsTimer);
            // this.idleSecondsTimer=null;
            // }
            this.queryDrumPicking()
            this.$bvToast.toast("Start Picking Baby Drum", {
              title: "Success",
              variant: "success",
              solid: true,
            });

          })
          .catch((err) => {
            this.$bvToast.toast("Drum Picking ALready Processed, Please Refresh the Page", {
                title: "Failed",
                variant: "danger",
                solid: true,
              });
              this.disableStart = false;
              this.disableFinish = true;
              return
          });
          this.babyModal = true;
      },

      ajaxSearch(search, loading) {
        // return console.log(this.filteredItems)
        loading(true);
          if (search !== "") {
            var orderNumberPicklist = this.filteredItems.map(x => x.soNumber);

            orderNumberPicklist = orderNumberPicklist.filter(soNumber => soNumber !== undefined && soNumber !== "");

            let uniqueData = orderNumberPicklist.filter((value, index, self) => self.indexOf(value) === index);
            this.soNumberList = uniqueData.filter(soNumber => soNumber.includes(search));

            this.soNumberList.sort((a, b) => a.localeCompare(b));
            
          }
        loading(false);
      },

      // method to end pick baby drum //

      async finishPick() {
        var dataPicklist = this.$store.getters["picklist/getPicklistID"];
  
        var sendStatus = this.dataFetchRow.props;
        sendStatus.babyDrumComplete = true;

        var station = this.$store.getters["plant/getStationId"];
        station.props.needPicker = false;
  
        var update = {
          status: "Machine Selected",
          props: sendStatus,
          picklistId: dataPicklist.id,
          timestamp: "BDE",
          stationId: station.id,
          payload: station,
        };
        
        this.startTimer=null;
        this.startPauseInterval();
        this.$store
          .dispatch("fetch/finishPickBabyDrum", {
            id: this.dataFetchRow.id,
            data: update,
          })
          .then(() => {
            this.queryDrumPicking()
            this.babyModal = false;
            this.$bvToast.toast("Finish Picking Baby Drum", {
              title: "Success",
              variant: "success",
              solid: true,
            });
            this.$bvToast.toast("baby Drum updated", {
              title: "Data Station Updated",
              autoHideDelay: 3000,
              variant: "success",
              solid: true,
            });
          })
          .catch((err) => {
            this.$bvToast.toast("Drum Picking ALready Processed, Please Refresh the Page", {
                title: "Failed",
                variant: "danger",
                solid: true,
              });
              this.disableStart = false;
              this.disableFinish = true;
              return
          });
      },
    },
    computed: {
      userInfo() {
        const user = this.$store.getters["auth/getActiveUser"]
        return { username: user.username, email: user.email}
      },
      
      permission() {
        return userAccess("ongoing job order");
      },
      pauseSetting() {
        let pauseSetting = this.$store.getters["pause/getPauseSetting"];
        return pauseSetting[0];
      },
      getPauseJob() {
        let pauseSetting = this.$store.getters["pause/getPauseSetting"];
        this.IDLE_TIMEOUT = pauseSetting[0]?.intervalTime * 60;
        var user = this.$store.getters["auth/getActiveUser"];
        let pausejobs = this.$store.getters["pause/getPauseJobs"];
        var currpause = pausejobs.filter(
          (x) => x.user?.username == user.username || x.user?.email == user.email
        );
        var hasIdleTrue = currpause.filter((data) => data.props.idleRun == true);
        if (hasIdleTrue.length == 1) {
          this.idleSecondsCounter = this.IDLE_TIMEOUT - 1;
          return hasIdleTrue[0];
        } else {
          return currpause[0];
        }
      },
      getUser() {
        var user = this.$store.getters["auth/getActiveUser"];
        return { username: user.username, email: user.email };
      },
  
      selectMachine() {
        return this.$store.getters["plant/getStations"]
        .filter(el => el.props.jobOrder == undefined)
        .map((el) => {
          return {
            ...el,
            label: el.name,
          };
        });
      },

      filteredItems() {
        let drumPicking = this.$store.getters["fetch/getOngoingFetch"].map(el => {
          let status = "";
          if (el.status === "Rejected") {
            status = "Can't Pick";
          } else if (el.status === "Approved") {
            status = "Picking";
          } else {
            status = el.status;
          }
          var isBabyDrum;
          if (el.props.needPicker == true) {
            isBabyDrum = "Baby Drum";
          } else {
            isBabyDrum = "Mama Drum";
          }
          let pickList = el.item.map((item) =>
            item.length != 0 ? item.orderNumber : el.props.pickListNumber
          );
          return {
            ...el,
            fetchingID:
              el.orderNumber.split("/")[2] +
              "/" +
              el.orderNumber.split("/")[3] +
              "/" +
              el.orderNumber.split("/")[4],
            pickList: pickList.join(", "),
            createdAtFormatted: dateFormat(el.createdAt).split(',')[0],
            status: status,
            babyormama: isBabyDrum,
          };
        })
        .map((item) => {
          let newType = item.props.type;
          if (item.props.type === "Direct Sale") {
            newType = "Direct Sale - 直销";
          } else if (item.props.type === "Cutting") {
            newType = "Cutting - 剪线";
          } else if (item.props.type === "Recoil") {
            newType = "Recoil - 卷线换轴";  
          }
          
          var picklistArr = [];
          item.item.map((el) => {
            picklistArr.push(el.pickList.id)
          })

          const uniquePicklistsCount = new Set(picklistArr).size;
  
          let newPickList = item.pickList.id;

          if (item.babyormama == "Mama Drum") {
            newPickList = ""
          }
          if (item.babyormama == "Baby Drum") {
            newPickList = item.pickList.id
          }
  
          if (item.drumID == null) {
            item.drumID = item.props.babyDrumType
  
            if (item.props.babyDrumType == "W"){
            item.drumID = "Wooden"
          }
            else if (item.props.babyDrumType == "S"){
            item.drumID = "Steel"
            }
          if (item.props.babyDrumType == "A"){
            item.drumID = "Active"
          }
            else if (item.props.babyDrumType == "I"){
              item.drumID = "Inactive"
            }
          }
  
          return {
            ...item,
            pickList: newPickList,
            type: newType,
            fromBatch: item.item[0].pickList.fromBatch,
            binLocation:item.props.binLocation,
            lineItemDescription: item.item[0].pickList.lineItem + "," + item.item[0].pickList.lineDescription,
            cableLength: item.item[0].pickList.cableLength,
            quantity: item.item[0].pickList.quantity,
            cableWeight: item.item[0].pickList.cableWeight,
            soNumber: item.item[0].pickList.orderNumber,
            uniquePicklistsCount: uniquePicklistsCount
          };
        })

        if(this.searchSoNumber != "" && this.searchSoNumber != null){
          let finalData = drumPicking.filter(
            (picklist) =>
            picklist.soNumber == this.searchSoNumber
          );
          
          return finalData;
        }
        return drumPicking
      },
      rows() {
        return this.filteredItems.length;
      },
    },
    watch: {
      afkmodal(value) {
      if(value) {
        // get reason list of pause job
        this.$store.dispatch("plant/getReasonList").then((res) => {
          var PauseJobReason = res.filter((x) => x.reasonName == "Pause Job");
          var allreason = PauseJobReason[0].reasonList;
          var activeReason = allreason.filter((x) => x.status === "Active")
          this.pauseReasonOptions = activeReason.map((x) => x.reason)
        });
      }
    },
      perPage(perPageNew) {
        this.$session.set("perPageOngoingDrumPicking", perPageNew);
      },
      sortBy(sortByNew) {
        this.$session.set("sortByOngoingDrumPicking", sortByNew);
      },
      sortDesc(sortDescNew) {
        this.$session.set("sortDescOngoingDrumPicking", sortDescNew);
      },
    },
  };
  </script>
  <style>
  .row-reject {
    background-color: yellow;
  }
  .row-waiting {
    background-color: rgb(255, 190, 70);
  }
  .row-urgent {
    background-color: rgba(255, 0, 0, 0.514)
  }
  .custom-bold-and-large {
    font-weight: bold;
    font-size: 18px;
  }
  </style>
  